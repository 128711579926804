<template>
    <el-dialog
        v-model="visibility"
        center
        :width="isNameSection ? '490px' : '630px'"
        :show-close="false"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
        :append-to-body="true"
        @close="$emit('onClose')"
        custom-class="add-customer-modal"
    >
        <div class="add-customer-modal__header">
            <div class="add-customer-modal__header--icon" v-if="!isNameSection">
                <img src="/media/buying/icons/mail-outline-icon.svg" width="20" alt="">
            </div>
            <span>{{ isNameSection ? $t('Create a Company') : createCompanyForm.companyName }}</span>
        </div>

        <div class="create-a-company" v-if="isNameSection">
            <el-form
                ref="createCompany"
                :model="createCompanyForm"
                :rules="createCompanyRules"
                @submit.prevent="handleSaveName"
            >
                <el-form-item prop="companyName">
                    <el-input
                        v-model="createCompanyForm.companyName"
                        :placeholder="$t('What is this company called?')"
                    ></el-input>
                </el-form-item>
            </el-form>
        </div>

        <div class="add-emails" v-else>
            <el-form
                ref="addEmail"
                :model="addEmailsForm"
                :rules="addEmailsRules"
            >
                <el-form-item prop="email">
                    <el-input
                        v-model="addEmailsForm.email"
                        :placeholder="$t('Add Email')"
                    ></el-input>
                </el-form-item>
                <button class="add-emails__btn" @click.prevent="handleAddEmails" :disabled="emailAddLoading">
                    <CustomLoader hide-text v-if="emailAddLoading"/>
                    <img src="/media/buying/icons/plus.svg" width="15" alt="" v-else>
                </button>
            </el-form>

            <div class="emailErrorMsg" v-if="emailErrorMsg.text">
                <div class="emailErrorMsg__loading" v-if="openCustomerModelLoading">
                    <CustomLoader />
                </div>
                <inline-svg src="/media/buying/icons/info_black.svg"></inline-svg>
                <div v-html="emailErrorMsg.text" @click="handleOpenCustomerPopup" v-if="emailErrorMsg.data"></div>
                <div v-html="emailErrorMsg.text" v-else></div>
            </div>

            <div class="added-emails__title">
                {{ $t('Added Emails') }}
            </div>

            <div class="added-emails">
                <div class="added-emails__item" v-for="(email, index) in addedEmails" :key="index"
                     v-if="addedEmails.length">
                    <span>{{ email }}</span>
                    <button @click="removeEmail(email)">
                        <img src="/media/buying/icons/delete-icon.svg" alt="">
                    </button>
                </div>

                <div class="added-emails__item justify-content-center" v-else>
                    {{ $t('No emails added') }}
                </div>
            </div>
        </div>

        <div class="add-customer-modal__footer">
            <button class="btn main-btn-outline btn-outline" @click="handleCancel">
                {{ $t('Cancel') }}
            </button>
            <button class="btn main-btn btn-shadow" @click="handleSaveName" v-if="isNameSection">
                {{ $t('Add Emails') }}
            </button>
            <button
                v-else
                class="btn main-btn btn-shadow"
                :data-kt-indicator="submitButtonLoading ? 'on' : 'off'"
                :disabled="submitButtonLoading || !addedEmails.length"
                @click="handleSubmit"
            >
                <span class="indicator-label">{{ $t('Save') }}</span>
                <span class="indicator-progress">
                    {{ $t("pleaseWait") }}
                    <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
            </button>
        </div>
    </el-dialog>
</template>

<script>
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import CustomLoader from "@/buying-teams/shared-components/utils/CustomLoader";
import { SelectCustomersContainer } from "@/store/models/bank/feedback/SelectCustomersContainer";

export default {
    name: "AddCustomerModal",
    components: {CustomLoader},
    props: {
        visibility: Boolean,
        customersData: [Object, SelectCustomersContainer]
    },
    emits: ['onClose', 'onAddNewCustomer', 'onOpenCustomerPopup'],
    data() {
        var validateEmailExists = (rule, value, callback) => {
            if (value === '') {
                callback(new Error(this.$t('Please input Email')));
            } else {
                if (this.addedEmails.includes(value)) {
                    callback(new Error(this.$t('Email already exists')));
                }
                callback();
            }
        };
        return {
            isNameSection: true,
            createCompanyForm: {
                companyName: "",
            },
            addEmailsForm: {
                email: "",
            },
            createCompanyRules: {
                companyName: [
                    {
                        required: true,
                        message: this.$t("Please input Company Name"),
                        trigger: "change"
                    }
                ]
            },
            addEmailsRules: {
                email: [
                    {
                        required: true,
                        message: this.$t("Please input Email"),
                        trigger: "change"
                    },
                    {
                        required: true,
                        type: "email",
                        message: this.$t("Please input correct email"),
                        trigger: "change"
                    },
                    {
                        validator: validateEmailExists,
                        trigger: 'change'
                    },
                ]
            },
            addedEmails: [],
            submitButtonLoading: false,
            emailErrorMsg: { text: '', data: null },
            emailAddLoading: false,
            openCustomerModelLoading: false
        }
    },
    methods: {
        handleSaveName() {
            this.$refs.createCompany.validate((valid) => {
                if (valid) {
                    this.isNameSection = false;
                }
            })
        },
        handleAddEmails() {
            this.emailErrorMsg = { text: '', data: null };
            this.$refs.addEmail.validate((valid) => {
                if (valid) {
                    this.emailAddLoading = true;
                    store.dispatch(Actions.CHECK_EMAIL, {email: this.addEmailsForm.email}).then((res) => {
                        if (!res.data.data.is_registered_user) {
                            this.addedEmails.push(this.addEmailsForm.email);
                            this.addEmailsForm.email = '';
                            this.$refs.addEmail.resetFields()
                        } else {
                            if (res.data.data.is_bank_user) {
                                this.emailErrorMsg = { text: this.$t('The email you are trying to add here already exists.') }
                            } else {
                                this.emailErrorMsg = {
                                    text: this.$t('The email you are trying to add here already exists - Go to {company} to add to feedback session', {
                                        company: `<a>${res.data.data.entity_name}</a>`,
                                    }),
                                    data: res.data.data
                                };
                            }
                        }
                    }).catch(() => {
                        this.emailErrorMsg = this.$t('Something went wrong.')
                    }).finally(() => {
                        this.emailAddLoading = false;
                    })
                }
            })
        },
        removeEmail(email) {
            this.addedEmails = this.addedEmails.filter((item) => item !== email);
        },
        handleSubmit() {
            const payload = {
                companyName: this.createCompanyForm.companyName,
                emails: this.addedEmails
            };
            this.$emit('onAddNewCustomer', payload);
        },
        handleCancel() {
            if (this.isNameSection) {
                this.$emit('onClose');
            } else {
                this.addEmailsForm.email = '';
                this.addedEmails = [];
                this.emailErrorMsg = { text: '', data: null };
                this.$refs.addEmail.resetFields();
                this.isNameSection = true;
            }
        },
        handleOpenCustomerPopup() {
            this.openCustomerModelLoading = true;
            this.$emit('onOpenCustomerPopup', this.emailErrorMsg.data.entity_id);
        }
    }
}
</script>

<style lang="scss">
.swal2-container {
    z-index: 3000;
}

.add-customer-modal {
    .el-dialog__header {
        padding: 0;
    }

    &__header {
        display: flex;
        align-items: center;

        span {
            font-weight: 700;
            font-size: 24px;
            line-height: 178.52%;
            color: #000000;
        }

        &--icon {
            background: linear-gradient(134.84deg, #AFB8F4 -50.01%, #178BF7 138.98%);
            width: 44px;
            height: 44px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 12px;

            img {
                filter: invert(1);
            }
        }
    }

    .emailErrorMsg {
        background: #F8F8F8;
        border-radius: 5.28234px;
        padding: 7px 10px;
        margin-bottom: 19px;
        font-weight: 500;
        font-size: 10px;
        line-height: 16px;
        color: #FF5959;
        display: flex;
        align-items: center;

        svg {
            width: 20px;
            height: 20px;
            margin-right: 4px;

            path {
                fill: #E22D21;
            }
        }

        a {
            text-decoration: underline;
            color: #435BF4;
            cursor: pointer;
            &:hover {
                color: #435BF4;
            }
        }

        &__loading {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            border-radius: 14px;
            background: #fff6;
            backdrop-filter: blur(3px);
            z-index: 2;
        }
    }

    .el-input__inner {
        background: #F8F8F8;
        box-shadow: inset 0px 1.12463px 4.4985px rgba(0, 0, 0, 0.05);
        border-radius: 5.28234px;
        height: 56px;
        border-color: transparent;

        &::placeholder {
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: #000000;
            opacity: 0.5;
        }
    }

    .el-form-item.is-error .el-input__inner {
        border-color: #f56c6c;
    }

    .create-a-company {
        margin-bottom: 40px;
        margin-top: 24px;
    }

    .add-emails {
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        margin-top: 26px;
        padding-top: 16px;

        .el-input__inner {
            &::placeholder {
                text-transform: uppercase;
            }
        }

        .el-form {
            display: flex;
            width: 100%;
            gap: 10px;

            .el-form-item {
                width: 100%;
            }
        }

        &__btn {
            background: rgba(67, 91, 244, 0.05);
            border-radius: 5.28234px;
            width: 68px;
            height: 56px;
            border: none;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: .3s ease-in-out;

            .content-loader > div {
                margin-top: 0 !important;
            }

            &:not(:disabled):hover {
                background: #e9ebff;
            }

            &:disabled {
                opacity: .5;
            }
        }

        .added-emails {
            display: flex;
            flex-direction: column;
            gap: 2px;
            margin-bottom: 24px;

            &__title {
                font-weight: 700;
                font-size: 16px;
                line-height: 13px;
                color: #515151;
                margin-bottom: 12px;
                margin-top: 10px;
                margin-left: 13px;

            }

            &__item {
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 66px;
                padding: 13px;
                border-radius: 8px;

                span {
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 19px;
                    color: #3E3E3E;
                }

                &:nth-child(odd) {
                    background: #FAFAFC;
                }

                button {
                    background: transparent;
                    border: 0;
                    padding: 0;

                    img {
                        transition: .3s ease-in-out;
                        filter: invert(34%) sepia(12%) saturate(28%) hue-rotate(316deg) brightness(89%) contrast(82%);
                    }

                    &:hover {
                        img {
                            filter: invert(22%) sepia(61%) saturate(4166%) hue-rotate(352deg) brightness(93%) contrast(90%);
                        }
                    }
                }
            }
        }
    }

    &__footer {
        width: 100%;
        display: flex;
        justify-content: center;
        gap: 20px;

        .btn {
            min-width: 143px;

            &.main-btn-outline {
                border: 1px solid #435BF4;
                height: 51px;
            }

            &.main-btn {
                box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.19), 0px 6px 43px rgba(0, 0, 0, 0.13);
            }
        }
    }

    @media (max-width: 640px) {
        --el-dialog-width: 98% !important;
    }
}
</style>
