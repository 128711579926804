import { Constants } from "@/core/config/constants";
import store from "@/store";
import { BankModel } from "@/store/models/bank/BankModel";
import { v4 as uuidv4 } from "uuid";

export class SelectCustomersContainer {
    surveyId: number;
    countries: string[];
    productAreas: string[];
    currentUserBank: BankModel;
    allCustomers: BSCCBusinessModel[];
    finalData: Map<number, BSCCBusinessModel>;

    constructor(obj) {
        this.surveyId = obj ? obj['survey'].id : null;
        this.countries = obj ? obj['survey'].countries : [];
        this.productAreas = obj ? obj['survey'].product_areas : [];
        this.currentUserBank = store.getters.bank;
        this.allCustomers = (obj.allBusinesses || []).map(c => new BSCCBusinessModel(c, this.countries, this.productAreas));
        this.finalData = new Map<number, BSCCBusinessModel>();
    }

    get selectedCustomers() {
        return this.allCustomers.filter((c: BSCCBusinessModel) => c.isSelected);
    }

    get getFinalData(): BSCCBusinessModel[] {
        return Array.from(this.finalData.values());
    }

    get getAllSelectedUsers() {
        let users = new Map();
        this.allCustomers.forEach((b: BSCCBusinessModel) => {
            if (b.isSelected) {
                this.finalData.set(b.business_id, b);
                b.matchedSelectedUsers.forEach((u: BSCCBUserModel) => {
                    if (u.selectedCountries.length && u.selectedProductAreas.length) {
                        users.set(u.user_id, u);
                    }
                })
            }
        })
        return Array.from(users.values()).map((u: BSCCBUserModel) => {
            return {
                id: u.user_id,
                product_areas: u.selectedProductAreas,
                countries: u.selectedCountries,
            }
        });
    }

    getFilteredData(search: string): BSCCBusinessModel[] {
        let data: BSCCBusinessModel[] = this.allCustomers;
        return search ? data.filter((item: BSCCBusinessModel) => {
            return item.business_name.toLowerCase().includes(search.toLowerCase())
        }) : data;
    }

    selectAll() {
        this.allCustomers.forEach((val: BSCCBusinessModel) => {
            val.setAllUsersSelected(this.countries, this.productAreas);
        })
    }

    deselectAll() {
        this.allCustomers.forEach((val: BSCCBusinessModel) => {
            val.removeAllUsersSelected();
        });
    }

    addNewCustomers(data) {
        let payload = new BSCCBusinessModel({
            id: data.businessId,
            name: data.companyName,
            users: data.users.map((val: any) => {return {
                ...val,
                country: '-',
                first_name: val.email,
                job_title: '-',
            }}),
        }, this.countries, this.productAreas,true);
        this.allCustomers.unshift(payload);
    }

    checkCustomerExist(businessId) {
        return this.allCustomers.find((item: BSCCBusinessModel) => item.business_id === businessId);
    }

    createNewBusinessModelData(data: any) {
        return new BSCCBusinessModel(data, this.countries, this.productAreas)
    }
}

export class BSCCBusinessModel {
    business_id: number;
    business_name: string;
    icon_path: string;
    users: BSCCBUserModel[];
    countries: Set<any>;
    productAreas: Set<any>;
    matchedSelectedUsers: any[];
    isSelected: boolean;
    isRegistered: boolean;

    constructor(obj, baseCountries, baseProductAreas, isNewUser: boolean = false) {
        this.matchedSelectedUsers = [];
        this.users = [];
        this.isSelected = false;
        this.countries = new Set();
        this.productAreas = new Set();
        this.business_id = obj['id'];
        this.business_name = obj['name'];
        this.isRegistered = obj['is_registered'];
        this.icon_path = obj['icon_path'] ? obj['icon_path'] : Constants.BUSINESS_TMP_LOGO;
        this.setUserData(obj['users'], baseCountries, baseProductAreas, isNewUser);
    }

    setUserData(users, baseCountries, baseProductAreas, isNewUser) {
        this.users = users ? users.map(u => {

            const matchedCountries: string[] = [];
            const matchedProductAreas: string[] = [];

            baseCountries.forEach(ct => {
                if (u.countries.includes(ct)) {
                    matchedCountries.push(ct);
                    this.countries.add(ct);
                }
            })

            baseProductAreas.forEach(pa => {
                if (u.product_areas.includes(pa)) {
                    matchedProductAreas.push(pa);
                    this.productAreas.add(pa);
                }
            })

            if (matchedCountries.length && matchedProductAreas.length) {
                this.matchedSelectedUsers.push(new BSCCBUserModel(u, matchedCountries, matchedProductAreas));
                this.isSelected = true;
            }

            return new BSCCBUserModel(u, matchedCountries, matchedProductAreas);
        }) : [];
    }

    setAllUsersSelected(baseCountries, baseProductAreas) {
        this.countries = baseCountries;
        this.productAreas = baseProductAreas;
        this.users.forEach((user) => {
            user.setUserCountryProductAreaData(baseCountries, baseProductAreas);
        });
        this.matchedSelectedUsers = this.users;
        this.isSelected = true;
    }

    removeAllUsersSelected() {
        this.countries = new Set();
        this.productAreas = new Set();
        this.users.forEach((user) => {
            user.setUserCountryProductAreaData([], []);
        });
        this.matchedSelectedUsers = [];
        this.isSelected = false;
    }

    addUsers(ides) {
        this.matchedSelectedUsers = [];
        this.countries = new Set();
        this.productAreas = new Set();
        this.users.forEach(u => {
            if (ides.includes(u.user_id)) {
                this.matchedSelectedUsers.push(u);
            }
            u.selectedCountries.forEach(c => this.countries.add(c));
            u.selectedProductAreas.forEach(p => this.productAreas.add(p));
        })
        this.isSelected = !!this.matchedSelectedUsers.length;
    }

    cancelChanges(users: any[]) {
        this.users.forEach((u: BSCCBUserModel) => {
            users.forEach((item: any) => {
                if (u.user_id === item.user_id) {
                    u.selectedProductAreas = item.selectedProductAreas
                    u.selectedCountries = item.selectedCountries
                }
            })
        })
    }

    addNewUser(user: any) {
        let newUser = new BSCCBUserModel({
            ...user,
            first_name: user.email,
            job_title: '-',
            country: '-',
            is_mock_user: true,
        }, user.countries, user.product_areas);

        this.matchedSelectedUsers.push(newUser);
        this.users.push(newUser);
    }

    get checkUserMatched() {
        let users: BSCCBUserModel[] = [];
        this.users.forEach((u: BSCCBUserModel) => {
            if (u.selectedCountries.length && u.selectedProductAreas.length) {
                users.push(u)
            }
        })

        if (!this.isSelected) {
            this.matchedSelectedUsers = users
        }

        return users
    }

    get getCountries() {
        return Array.from(this.countries);
    }

    get getProductAreas() {
        return Array.from(this.productAreas);
    }
}

export class BSCCBUserModel {
    user_id: number;
    countries: string[];
    product_areas: string[];
    first_name: string;
    last_name: string;
    job_title: string;
    country: string;
    email: string;
    icon_path: string;
    selectedCountries: string[];
    selectedProductAreas: string[];
    userHaveBank: boolean = true;

    constructor(obj, matchedCountries, matchedProductAreas) {
        this.user_id = obj['id'];
        this.countries = obj['countries'];
        this.product_areas = obj['product_areas'];
        this.first_name = obj['first_name'];
        this.last_name = obj['last_name'] || '';
        this.job_title = obj['job_title'] || 'Unknown';
        this.country = obj['country'] || 'Unknown';
        this.email = obj['email'];
        this.icon_path = obj['icon_path'] ? obj['icon_path'] : Constants.USER_TMP_LOGO;
        this.selectedCountries = matchedCountries;
        this.selectedProductAreas = matchedProductAreas;

        if (!this.first_name) {
            this.first_name = this.email;
            this.last_name = '';
        }

        this.checkIfUserHaveBank(obj['banks'] || []);
    }

    checkIfUserHaveBank(banks: any[]) {
        if (!banks.length) return;
        const bankIds = banks.map(b => b.id);
        let currentUser: BankModel = store.getters.bank;
        this.userHaveBank = bankIds.includes(currentUser.id);
    }

    setUserCountryProductAreaData(countries, productAreas) {
        this.selectedCountries = countries;
        this.selectedProductAreas = productAreas;

    }
}
